body {
    margin: 0;
    font-family: georgia, serif;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 650px) {
    body {
        -webkit-text-size-adjust: none;
    }
}
