#container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#content {
  margin: 0 92px;
}

.item {
  display: inline;
  white-space: nowrap;
  padding-left: 24px;
  font-size: 18pt;
  line-height: 1.5em;
  color: #8b8b8b;
}

a {
  text-decoration: none;
  color: #3d84b2;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: #222;
}

#intro p {
  margin-top: 120px;
  color: #8b8b8b;
  font-size: 20pt;
}

#ben {
  color: #000;
}

.topic {
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 1300px;
}

@media (max-width: 780px) {
  #content {
    margin: 0 48px;
  }

  #intro p {
    margin-top: 48px;
    font-size: 16pt;
  }

  .topic {
    display: block;
    margin-top: 64px;
  }

  .item {
    display: block;
    font-size: 16pt;
    white-space: normal;
    margin-bottom: 8px;
    line-height: 1.25em;
    padding-left: 0;
  }
}
